import { useEffect, useState } from "react";
import "./IndicatorDropdown.scss";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as LineChart } from "../../assets/icons/line-chart.svg";
import { ReactComponent as X } from "../../assets/icons/x.svg";

interface Props {
  studies: string[];
  activateStudy: (study: string) => void;
  deactivateStudy: (study: string) => void;
}

const CustomDropdown = ({ studies, activateStudy, deactivateStudy }: Props) => {
  const [activeStudies, setActiveStudies] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredStudies, setFilteredStudies] = useState<string[]>(studies);

  const handleSelect = (study: string) => {
    setActiveStudies([...activeStudies, study]);
    activateStudy(study);
  };

  const handleDeativate = (study: string) => {
    const newStudies = activeStudies.filter(
      (activeStudy) => activeStudy !== study
    );
    setActiveStudies(newStudies);
    deactivateStudy(study);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (searchValue === "") {
      setFilteredStudies(studies);
    } else {
      const filteredStudies = studies.filter((study) =>
        study.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredStudies(filteredStudies);
    }
  }, [searchValue, studies]);

  return (
    <div className="indicator-dropdown">
      <div className="indicator-dropdown-trigger" onClick={toggleDropdown}>
        <LineChart />
      </div>
      {isOpen && (
        <div className="indicator-dropdown-content ">
          <div className="indicator-dropdown-search-bar">
            <div className="indicator-dropdown-search-bar-start">
              {searchValue === "" ? (
                <Search />
              ) : (
                <span
                  onClick={() => setSearchValue("")}
                  className="indicator-close"
                >
                  <Close />
                </span>
              )}
              <input
                type="text"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
            <X onClick={toggleDropdown} />
          </div>
          <div className="indicator-dropdown-body tr-scrollbar">
            {filteredStudies.map((study) => (
              <div key={study} className="indicator-dropdown-item">
                <div
                  onClick={() => handleSelect(study)}
                  className={`indicator-dropdown-item-start ${
                    activeStudies.includes(study) ? "selected" : ""
                  }`}
                >
                  {study}
                </div>
                {activeStudies.includes(study) && (
                  <div
                    className="indicator-close"
                    onClick={() => handleDeativate(study)}
                  >
                    <Close />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
